.infoBox {
	visibility: visible;
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.5rem;
	background-color: whitesmoke;
	padding: 0.5rem;
	border-radius: 5px;
}

.infoBox svg {
	width: 20px;
	height: 20px;
}

.infoBox p {
	max-width: 300px;
	font-size: 14px;
	line-height: 1rem;
}
