.select-title {
	font-size: 0.9rem;
	font-weight: 400;
}

.cut-option {
	//display: inline-block;
	float: left;
	width: 15%;
	position: relative;
	//margin-top: 10px;
	margin-left: 1.1%;
	//overflow-x: hidden;
	//overflow-y: hidden;
}

.cut-option.large {
	width: 35%;
}

.layer-options-container {
	margin-top: 0px;
}

.layer-options-container .categories {
	float: left;
}

.layer-options-container .options {
	float: left;
	margin-left: 10px;
}

.layer-option-radio {
	background-color: #ffffff;
	color: #000000;
	display: inline-block;
	padding: 5px;
	padding-left: 25px;
	padding-right: 25px;
	margin-right: 5px;
	border-radius: 15px;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.2);
	border-style: solid;
	cursor: pointer;
	font-size: 0.9rem;
}

.layer-option-radio.selected {
	background-color: #56b8bb;
	color: #ffffff;
	border-color: #ffffff;
}

.layer-option-select {
	display: inline-block;
	width: 350px;
	margin-left: 0px;
}

.layer-option-select.quartile {
	width: 150px;
}

.layer-option-chips {
	display: inline-block;
	//width: 58%;
	//margin-left: calc(2% - 10px);
}

.layer-option-chips .layer-option-chip {
	padding: 10px;
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 10px;
	//background-color: #DBE2F9;
	background-color: #eef8f8;
	color: #000000;
	font-size: 0.85rem;
	display: inline-block;
	margin-left: 7px;
	font-weight: 400;
}

.aoc-select-option:hover {
	background-color: #56b8bb;
	color: white;
}

.aoc-select-option:hover div {
	color: white;
}

.logo-top-container {
	padding: 0px;
	float: left;
}

.logo-top-container::after {
	clear: both;
	display: block;
	content: " ";
	width: 100%;
}

.logo-top-container .logo {
	float: left;
	width: 100px;
	//margin-top: 10px;
}

.filter-options-toggle {
	padding-right: 0px;
	float: right;
	text-align: right;
	display: none;
}

.filter-options-toggle img {
	width: 25px;
}

.filter-options {
	float: left;
	margin-left: 10px;
	width: calc(100% - 110px);
	position: relative;
}

.location-search {
	width: 100%;
	position: relative;
	padding-right: 35px;
	overflow-x: hidden;
	border-width: 1px;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	display: block;
	background-color: #ffffff;
}

.location-search input {
	border-width: 0px;
	background-color: #ffffff;
}

.location-search .clear-icon {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 0.9rem;
	color: #454545;
	cursor: pointer;
	width: 22px;
	height: 22px;
	//border-radius: 50%;
	border-width: 0px;
	border-color: #000000;
	border-style: solid;
	color: #000000;
	text-align: center;
	padding-top: 0px;
	background-color: #ffffff;
	overflow: hidden;
}

.location-search .clear-icon img {
	width: 100%;
	height: 100%;
}

.logo-top-container .language-options {
	float: right;
}

.map-container {
	margin-top: 10px;
	margin-left: 0px;
	width: 100%;
	position: relative;
}

.top-text-info {
	background-color: #ffffff;
	position: absolute;
	top: 0;
	right: 0;
	width: 340px;
	padding: 10px;
	font-size: 0.85rem;
	margin-left: 15px;
}

.bottom-text-info {
	background-color: #ffffff;
	position: absolute;
	bottom: 0;
	left: 0;
	//width: 400px;
	width: 340px;
	padding: 10px;
	font-size: 0.85rem;
	margin-left: 15px;
}

.bottom-text-info.tooltip-info {
	left: auto;
	right: 0;
	width: auto;
	padding: 10px;
	font-size: 0.85rem;
	min-width: 200px;
	max-width: 300px;
	z-index: 999998 !important;
}

.bottom-text-info.tooltip-info table {
	width: 100%;
}

.bottom-text-info .bottom-text-info-top {
	font-size: 0.8rem;
	margin-bottom: 15px;
}

.bottom-text-info .bottom-text-info-bottom {
	font-size: 0.85rem;
	margin-top: 10px;
}

.close-filter-button {
	text-align: right;
}

.close-filter-button .clear-filters-button {
	color: #3983f7;
	background-color: #ffffff;
	border-radius: 15px;
	border-color: rgba(0, 0, 0, 0.2);
	border-style: solid;
	border-width: 1px;
	font-size: 0.8rem;
	width: 80px;
	height: 30px;
}

.close-filter-button .apply-filters-button {
	color: #ffffff;
	background-color: #3983f7;
	border-radius: 15px;
	border-color: #3983f7;
	border-style: solid;
	border-width: 1px;
	font-size: 0.8rem;
	width: 120px;
	height: 30px;
}

.zoom-control {
	position: absolute;
	right: 40px;
	//left: 40px;
	top: 30px;
	background-color: #ffffff;
	width: 40px;
	border-radius: 10px;
	overflow: hidden;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.1);
	border-style: solid;
	//z-index: 9999999 !important;
}

.zoom-control .toggle-container {
	position: relative;
	height: 40px;
	width: 100%;
	background-color: #dbe2f9;
	cursor: pointer;
}

.zoom-control .toggle {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 100%;
	text-align: center;
	color: #767676;
	font-weight: 400;
	font-size: 1.75rem;
}

.zoom-control .value {
	background-color: #ffffff;
	width: 100%;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: 600;
}

.summary-scorecard-action {
	border-radius: 10px;
	padding: 10px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: #eaeaea;
	color: #aeaeae;
	display: inline-block;
	margin-right: 5px;
	font-size: 0.9rem;
}

.summary-scorecard-action.active {
	background-color: #56b8bb;
	color: #ffffff;
	cursor: pointer;
}

.clear-all-filters {
	border-radius: 10px;
	padding: 10px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: #ffffff;
	color: #000000;
	border-width: 1px;
	border-color: #252525;
	display: inline-block;
	margin-right: 5px;
	font-size: 0.9rem;
	font-weight: 400;
	cursor: pointer;
}

.legend-toggle {
	border-width: 1px;
	border-color: #56b8bb;
	border-style: solid;
	border-radius: 10px;
	color: #56b8bb;
	background-color: transparent;
	width: 80px;
	outline: none;
	display: none;
}

.indicator-bar-legend {
	position: relative;
	height: 17px;
	//background: linear-gradient(90deg, #913624 0%, #F8A251 43.75%, rgba(207, 207, 207, 0.19) 58.85%, #7FB3D5 75%, #46729F 100%);
	// background: linear-gradient(90deg, #913624 0%, #F8A251 45%, rgba(207, 207, 207, 0.19) 52%, #7FB3D5 75%, #46729F 100%);
	background: linear-gradient(90deg, #ffefe0 0%, #f49e50 50%, #923725 100%);
	border-radius: 8px;
}

.indicator-bar-legend-one-tone {
	position: relative;
	height: 17px;
	//background: linear-gradient(90deg, #913624 0%, #F8A251 43.75%, rgba(207, 207, 207, 0.19) 58.85%, #7FB3D5 75%, #46729F 100%);
	// background: linear-gradient(90deg, #913624 0%, #F8A251 45%, rgba(207, 207, 207, 0.19) 52%, #7FB3D5 75%, #46729F 100%);
	background: linear-gradient(90deg, #dfe0e2 0%, #7eb0d1 50%, #4c719b 100%);
	border-radius: 8px;
}

.indicator-bar-legend-two-tone {
	position: relative;
	height: 17px;
	//background: linear-gradient(90deg, #913624 0%, #F8A251 43.75%, rgba(207, 207, 207, 0.19) 58.85%, #7FB3D5 75%, #46729F 100%);
	background: linear-gradient(
		90deg,
		#913624 0%,
		#f8a251 45%,
		rgba(207, 207, 207, 0.19) 52%,
		#7fb3d5 75%,
		#46729f 100%
	);
	// background: linear-gradient(90deg, #ffefe0 0%, #f49e50 50%, #923725 100%);
	border-radius: 8px;
}

@media (max-width: 799px) {
	.logo-top-container {
		width: 100%;
	}

	.cut-option {
		margin-top: 10px;
	}

	.filter-options {
		margin-left: 0px;
		width: 100%;
	}

	.location-search {
		//width: calc(100% - 50px);
		width: 100%;
	}

	.logo-top-container .logo {
		//display: none;
	}

	.logo-top-container .language-options {
		display: none;
	}

	.filter-options-toggle {
		//margin-top: 12px;
	}

	.cut-option {
		width: 47%;
		//width: 100%;
		//margin-bottom: 10px;
		//margin-left: 0px;
		//margin-right: 0px;
	}

	.cut-option.large {
		width: 100%;
	}

	.layer-option-select {
		width: 100%;
		margin-bottom: 0px;
		margin-left: 0px;
	}

	.select-title.quartile {
		width: 100%;
		margin-top: 10px;
	}

	.layer-option-select.quartile {
		//width: 200px;
		width: 100%;
	}

	.layer-options-container {
		margin-top: 0px;
	}

	.layer-options-container .categories {
		width: 100%;
		margin-bottom: 10px;
	}

	.layer-options-container .options {
		width: 100%;
		margin-left: 0;
	}

	.overlays-options-container {
		width: 100%;
	}

	.layer-option-chips {
		width: 100%;
		margin-left: 0px;
		margin-bottom: 0px;
	}

	.layer-option-chips .layer-option-chip {
		margin-left: 0px;
		margin-top: 10px;
		font-size: 0.8rem;
	}

	.filter-options {
		display: none;
	}

	.filter-options-toggle {
		text-align: right;
		display: block;
	}

	.bottom-text-info {
		//width: calc(100% - 20px);
		//margin-left: 10px;
		margin-left: 0;
		width: 100%;
		position: relative;
	}

	.zoom-control {
		right: 20px;
		top: 5px;
	}

	.bottom-text-info.tooltip-info {
		display: none;
	}

	.legend-toggle {
		display: inline;
	}
}

@media (max-width: 500px) {
	.select-title.quartile {
		width: 100%;
		margin-top: 10px;
	}

	.layer-option-select.quartile {
		width: 100%;
	}
}

@media (min-width: 800px) {
	.close-filter-button {
		display: none;
	}
}
