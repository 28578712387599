.partial-scorecard-popup {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 650px;
	height: calc(100% - 20px);
	overflow: hidden;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-radius: 10px;
	background-color: #ffffff;

	border-color: rgba(0, 0, 0, 0.1);
	border-width: 1px;
	border-style: solid;

	// box-shadow: -2px 1px 10px 0px rgba(0,0,0,0.88);
	// -webkit-box-shadow: -2px 1px 10px 0px rgba(0,0,0,0.88);
	// -moz-box-shadow: -2px 1px 10px 0px rgba(0,0,0,0.88);

	box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.4);
	-webkit-box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.4);

	z-index: 999999 !important;
}

.partial-scorecard-popup .popup-header {
	width: 100%;
	height: 50px;
	background-color: #56b8bb;
	position: relative;
	padding: 10px;
}

.partial-scorecard-popup .popup-header::after {
	clear: both;
	width: 100%;
	content: " ";
	display: block;
}

.partial-scorecard-popup .popup-header .title {
	color: #ffffff;
	font-size: 1.2rem;
	font-weight: 600;
	float: left;
	text-transform: uppercase;
	width: calc(100% - 40px);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.partial-scorecard-popup .popup-header .close {
	float: right;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border-color: #ffffff;
	border-width: 0px;
	border-style: solid;
	overflow: hidden;
	text-align: center;
	font-size: 1.35rem;
	color: #ffffff;
}

.partial-scorecard-popup .popup-header .close:hover {
	cursor: pointer;
}

.partial-scorecard-popup .popup-content {
	width: 100%;
	background-color: #ffffff;
	height: calc(100% - 50px);
	overflow-y: scroll;
	position: relative;
}

.full-scorecard-popup {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #ffffff;
	z-index: 999999 !important;
}

.full-scorecard-popup .popup-header {
	width: 100%;
	background-color: #ffffff;
	position: relative;
	margin-bottom: 10px;
}

.full-scorecard-popup .popup-header::after {
	clear: both;
	width: 100%;
	content: " ";
	display: block;
}

.full-scorecard-popup .popup-header .logo {
	float: left;
	margin-right: 10px;
}

.full-scorecard-popup .popup-header .logo img {
	width: 50px;
}

.full-scorecard-popup .popup-header .title {
	color: #56b8bb;
	font-size: 1.2rem;
	font-weight: 600;
	float: left;
}

.full-scorecard-popup .popup-header .close {
	float: right;
	width: 25px;
	height: 25px;
	//border-radius: 50%;
	border-color: #56b8bb;
	border-width: 0px;
	border-style: solid;
	overflow: hidden;
	text-align: center;
	font-size: 1rem;
	font-weight: 600;
	color: #56b8bb;
	overflow: hidden;
}

.full-scorecard-popup .popup-header .close img {
	width: 100%;
	height: 100%;
}

.full-scorecard-popup .popup-header .close:hover {
	cursor: pointer;
}

.full-scorecard-popup .popup-content {
	width: 100%;
	background-color: #ffffff;
	height: 100%;
	padding: 15px;
	overflow-y: scroll;
	padding-top: 5px;
	position: relative;
}

.scorecard-city-zip {
	font-size: 0.85rem;
}

.indicator-bar {
	position: relative;
	height: 22px;
}

.indicator-bar::after {
	background: linear-gradient(
		90deg,
		#913624 0%,
		#f8a251 43.75%,
		rgba(207, 207, 207, 0.19) 50%,
		#7fb3d5 75%,
		#46729f 100%
	);
	border-radius: 8px;
	height: 12px;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	content: " ";
	z-index: 1;
}

.indicator-bar .marker {
	position: absolute;
	bottom: 0;
	z-index: 2;
	width: 16px;
	height: 33px;
}

.indicator-bar .marker img {
	width: 100%;
	height: 100%;
}

.scorecard-legends {
	width: 100%;
}

.scorecard-legend .title {
	font-size: 0.85rem;
	color: #000000;
}

.scorecard-legend td {
	padding-bottom: 5px;
	text-align: left;
}

.scorecard-stat-box {
	border-width: 1px;
	border-style: solid;
	border-color: #56b8bb;
	border-radius: 15px;
}

.scorecard-stat-box .header {
	color: #56b8bb;
	font-weight: 600;
}

.scorecard-stat-box .padded-row td {
	padding: 10px;
}

.scorecard-stat-box .separator {
	content: " ";
	width: 100%;
	height: 1px;
	background-color: rgba(0, 0, 0, 0.15);
}

.scorecard-stat-box .separator.primary {
	background-color: #56b8bb;
}

.scorecard-stat-box .separator.mt {
	margin-top: 5px;
}

.scorecard-filters .header {
	background-color: #56b8bb;
	color: #ffffff;
	font-weight: 600;
	padding: 3px;
	padding-left: 8px;
	border-radius: 5px;
	font-size: 0.95rem;
	margin-top: 5px;
	position: relative;
}

.scorecard-filters .header .toggle-arrow {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.scorecard-filters .subheader {
	margin-top: 10px;
	font-size: 0.9rem;
	font-weight: 600;
	//color: #656565;
	color: #000000;
	margin-left: 5px;
}

.scorecard-filters .filter-point {
	margin-top: 5px;
	margin-left: 10px;
	font-weight: 400;
	color: #000000;
}

.scorecard-filters .filter-point .title {
	float: left;
	width: calc(100% - 30px);
	font-size: 0.85rem;
	color: #000000;
}

.scorecard-filters .subheader .title {
	float: left;
	width: calc(100% - 25px);
}

.scorecard-filters .point-selected,
.scorecard-filters .point-selected .title {
	//color: #E56A29;
	color: #000000;
}

.scorecard-filters .toggle {
	width: 20px;
	height: 10px;
	position: relative;
	border-width: 1px;
	border-radius: 10px;
	border-color: #000000;
	border-style: solid;
	content: " ";
	float: right;
	margin-top: 5px;
}

.scorecard-filters .point-selected .toggle {
	//border-color: #E56A29;
	border-color: #56b8bb;
	background-color: #56b8bb;
}

.scorecard-filters .toggle .circle {
	width: 6px;
	height: 6px;
	position: absolute;
	left: 2px;
	top: calc(50% - 3px);
	border-radius: 10px;
	background-color: #000000;
	content: " ";
}

.scorecard-filters .point-selected .toggle .circle {
	left: auto;
	right: 2px;
	//background-color: #E56A29;
	background-color: #ffffff;
}

@media (max-width: 760px) {
	.partial-scorecard-popup {
		top: 0px;
		left: 0px;
		right: 0px;
		width: 100%;
		height: 100%;
	}
	.scorecard-legend .title {
		font-size: 0.75rem;
	}
}

@media (min-width: 760px) {
	.scorecard-city-zip {
		font-size: 0.95rem;
	}

	.scorecard-filters .header {
		font-size: 1.1rem;
	}

	.scorecard-filters .subheader {
		font-size: 0.95rem;
	}

	.scorecard-filters .filter-point .title {
		font-size: 0.95rem;
	}
}

#scorecard {
	box-sizing: border-box;
	margin: auto;
	width: 1200px;
}
