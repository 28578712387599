$common-box-shadow: -1px 1px 10px -1px rgba(0, 0, 0, 0.1);

.print-sc-top-container {
	border-bottom-width: 0px;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0.1);
}

.print-sc-top-part {
	float: left;
	width: 50%;
	padding-bottom: 5px;
	position: relative;
}

.print-sc-top-part.left {
	border-right-width: 0px;
	border-right-style: solid;
	border-right-color: rgba(0, 0, 0, 0.1);
	//width: 70%;
	width: calc(71% - 20px);
	border-radius: 10px;
	box-shadow: $common-box-shadow;
	-webkit-box-shadow: $common-box-shadow;
	-moz-box-shadow: $common-box-shadow;
	padding: 15px;
	min-height: 210px;
}

.print-sc-top-part.right {
	width: calc(29% - 30px);
	margin-left: 20px;
	border-radius: 10px;
	box-shadow: $common-box-shadow;
	-webkit-box-shadow: $common-box-shadow;
	-moz-box-shadow: $common-box-shadow;
	padding: 15px;
}

.print-sc-overall-spi {
	padding-top: 20px;
	padding-bottom: 20px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0.1);
}

.print-sc-overall-spi .section {
	display: inline-block;
	margin-left: 20px;
	margin-right: 10px;
	font-size: 0.75rem;
	line-height: 1;
	color: #000000;
}

.print-sc-all-stats-container {
	margin-top: 20px;
}

.print-sc-all-stats-container::after {
	clear: both;
	content: " ";
	width: 100%;
	display: block;
}

.print-sc-all-stats-container .print-sc-stat-table {
	//padding: 15px;
	float: left;
	width: 32%;
	margin-right: 1%;
	overflow: hidden;
}

.print-sc-all-stats-container .print-sc-stat-bordered {
	border-width: 1px;
	border-style: solid;
	border-color: #56b8bb;
	border-radius: 15px;
	margin-bottom: 15px;
}

.print-sc-all-stats-container .print-sc-stat-table .table-title {
	font-size: 1rem;
	color: #56b8bb;
	font-weight: 600;
	padding-left: 10px;
	padding-top: 10px;
	padding-right: 10px;
}

.print-sc-stat-table .separator {
	width: 100%;
	height: 1px;
	background-color: #56b8bb;
	content: " ";
	margin-top: 10px;
}

.print-sc-stat-table .stat-table {
	margin-top: 15px;
}

.print-sc-stat-table .stat-table .stat-table-row {
	font-weight: 600;
}

.print-sc-stat-table .stat-table .stat-table-row td {
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0.1);
}

.stat-table-row td {
	font-size: 0.8rem;
}

.stat-table-more-info {
	border-color: rgba(0, 0, 0, 0.25);
	border-width: 1px;
	border-radius: 15px;
	font-size: 0.8rem;
	color: #56b8bb;
	font-weight: 400;
	text-align: center;
}

.back-stat-table-row td {
	font-size: 0.8rem;
}

.printable-sc-similar-labels {
	background-color: #ffffff;
	padding: 10px;
	border-radius: 0px;
	margin-bottom: 20px;
	//overflow: hidden;
	width: calc(100% - 20px);
	margin-left: 10px;

	box-shadow: 1px 1px 7px 4px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 1px 1px 7px 4px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 1px 1px 7px 4px rgba(0, 0, 0, 0.1);
}

.printable-sc-similar-label-title {
	font-weight: 600;
	font-size: 0.8rem;
	color: #000000;
}

.printable-sc-similar-label-values {
	margin-top: 10px;
}

.printable-sc-similar-label {
	display: inline-block;
	margin-right: 15px;
	color: #56b8bb;
	font-size: 0.7rem;
	cursor: pointer;
}

.printable-sc-map-url {
	position: absolute;
	left: 10px;
	bottom: 10px;
	line-height: 0.9;
}

.printable-sc-map-url a {
	font-size: 0.9rem;
	color: #56b8bb;
}

img {
	display: inline !important;
	vertical-align: auto;
	border-width: 0 !important;
}

@media (min-width: 760px) {
}
