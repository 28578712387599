$common-box-shadow: -1px 1px 10px -1px rgba(0,0,0,0.10);

.full-sc-top-container {
    border-bottom-width: 0px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,0.10);
}
  
.full-sc-top-part {
    float: left;
    width: 50%;
    padding-bottom: 5px;
}
  
.full-sc-top-part.left {
    border-right-width: 0px;
    border-right-style: solid;
    border-right-color: rgba(0,0,0,0.10);
    width: calc(70% - 20px);
    border-radius: 10px;
    box-shadow: $common-box-shadow;
    -webkit-box-shadow: $common-box-shadow;
    -moz-box-shadow: $common-box-shadow;
    padding: 15px;
}

.full-sc-top-card-title {
    float: left;
    width: 48%;
}

.full-sc-top-card-title-right {
    float: right;
    padding-right: 10px;
    width: 50%;
    text-align: right;
}
  
.full-sc-top-part.right {
    width: calc(30% - 0px);
    margin-left: 20px;
    border-radius: 10px;
    box-shadow: $common-box-shadow;
    -webkit-box-shadow: $common-box-shadow;
    -moz-box-shadow: $common-box-shadow;
    padding: 15px;
    min-height: 242px;
}
  
.full-sc-legend {
    margin-top: 3px;
}
  
.full-sc-legend-title {
    font-size: 0.92rem;
    color: #000000;
}
  
.full-sc-overall-spi {
    padding-top: 25px;
    padding-bottom: 0px;
    border-bottom-width: 0px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,0.10);
}
  
.full-sc-overall-spi .section {
    display: inline-block;
    margin-left: 20px;
    margin-right: 10px;
    font-size: 0.75rem;
    line-height: 1;
    color: #000000;
}
  
.full-sc-all-stats-container {
    margin-top: 0px;
}
  
.full-sc-all-stats-container .full-sc-stat-table {
    padding: 15px;
    border-width: 0px;
    border-style: solid;
    border-color: #56B8BB;
    border-radius: 10px;
    box-shadow: $common-box-shadow;
    -webkit-box-shadow: $common-box-shadow;
    -moz-box-shadow: $common-box-shadow;
    margin-bottom: 15px;
}
  
.full-sc-all-stats-container .full-sc-stat-table .table-title {
    font-size: 0.97rem;
    color: #56B8BB;
    font-weight: 600;
    text-align: left;
}
  
.full-sc-stat-table .separator {
    width: 100%;
    height: 1px;
    background-color: #56B8BB;
    content: " ";
    margin-top: 10px;
}

.full-sc-separator {
    width: 100%;
    height: 1px;
    background-color: rgba(0,0,0,0.10);
    content: " ";
    margin-top: 10px;
}
  
.full-sc-stat-table .stat-table {
    margin-top: 15px;
}

.full-sc-stat-table .stat-table-container {
    padding-left: 20px;
}

.full-sc-stat-table .stat-table-container.container-main {
    padding-left: 10px;
}
  
.full-sc-stat-table .stat-table .stat-table-row td {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,0.10);
    font-size: 0.80rem;
}

.stat-table-row td.spi {
    width: 70px;
    font-size: 0.80rem;
    text-align: center;
}

.stat-table-row td.spi img {
    height: 10px;
    margin-right: 2px;
}

.stat-table-row td.compare-to {
    width: 70px;
    font-size: 0.70rem;
    line-height: 1.2;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
}

.stat-title-row td {
    font-size: 0.90rem;
    color: #000000;
    text-align: left;
}
  
.stat-table-more-info {
    border-color: rgba(0,0,0,0.25);
    border-width: 1px;
    border-radius: 15px;
    font-size: 0.80rem;
    color: #56B8BB;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
}

.stat-table-more-info:hover {
    background-color: #56B8BB;
    color: #FFFFFF;
}

.stat-table-more-info.selected {
    background-color: #56B8BB;
    color: #FFFFFF;
    border-color: rgba(0,0,0,0.70);
}

.full-sc-all-filters-container {
    padding: 8px;
    // border-width: 1px;
    // border-style: solid;
    // border-color: #56B8BB;
    // border-radius: 15px;
    border-radius: 10px;
    box-shadow: $common-box-shadow;
    -webkit-box-shadow: $common-box-shadow;
    -moz-box-shadow: $common-box-shadow;
    margin-bottom: 15px;
}

.full-sc-filters-section {
    float: left;
    width: 310px;
}

.full-sc-stats-section {
    float: left;
    width: calc(100% - 320px);
    margin-left: 10px;
}

.stat-layout-small {
    display: none;
}
.stat-layout-big {
    display: block;
}

.more-info-popover {
    position: absolute;
    //left: 50%;
    //top: 50%;
    width: 90%;
    max-width: 800px;
    //transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-color: rgba(0,0,0,0.25);
    //border-color: #56B8BB;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    padding: 15px;
    color: #000000;
    z-index: 999999 !important;
}

.more-info-close {
    border-radius: 10px;
    border-color: #56B8BB;
    border-width: 1px;
    color: #56B8BB;
    width: 120px;
    font-weight: 400;
    font-size: 0.80rem;
}

.more-info-close:hover {
    background-color: #56B8BB;
    color: #FFFFFF;
}

@media(max-width: 760px){
    .stat-layout-small {
        display: block;
    }
    .stat-layout-big {
        display: none;
    }
    .full-sc-top-part.left {
        width: 100%;
        text-align: left;
        border-right-width: 0;
    }
    .full-sc-top-part.right {
        min-height: auto;
    }

    .full-sc-top-card-title {
        width: 100%;
    }
    
    .full-sc-top-part.right {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }    
    
    .full-sc-filters-section {
        display: none;
    }

    .full-sc-stats-section {
        margin-left: 0;
        width: 100%;
    }

    .full-sc-stat-table .stat-table-container {
        padding-left: 0px;
    }
    
    .full-sc-stat-table .stat-table-container.container-main {
        padding-left: 0px;
    }

    .stat-table-row td.spi {
        width: 50px;
        font-size: 0.70rem;
    }

    .stat-table-row td.spi img {
        height: 8px;
    }

    .full-sc-all-stats-container .full-sc-stat-table {
        padding-top: 0;
        border-radius: 0;
        padding: 0;
        border-width: 0;
    }

    .full-sc-all-stats-container .full-sc-stat-table .table-title {
        background-color: #56B8BB;
        color: #FFFFFF;
        padding: 10px;
        position: relative;
    }

    .full-sc-all-stats-container .full-sc-stat-table .table-title .toggle-arrow {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .full-sc-all-stats-container .full-sc-stat-table .stat-info-main {
        background-color: rgba(86, 184, 187,0.10);
        padding: 10px;
    }

    .full-sc-all-stats-container .full-sc-stat-table .stat-table-container.container-main {
        padding-left: 10px;
        padding-right: 10px;
    }

    .full-sc-all-stats-container .full-sc-stat-table .stat-info-container {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media(min-width: 760px){
    .full-sc-all-stats-container .full-sc-stat-table .table-title {
        font-size: 1.15rem;
    }

    .stat-title-row td {
        font-size: 1.0rem;
    }

    .stat-table-row td.spi {
        font-size: 0.97rem;
    }

    .stat-table-row td.compare-to {
        font-size: 0.80rem;
        width: 80px;
    }
}