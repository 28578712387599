.mapInfoBox {
	visibility: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.5rem;
	background-color: whitesmoke;
	padding: 0.5rem;
	border-radius: 5px;
}

.mapInfoBox svg {
	width: 20px;
	height: 20px;
}

.mapInfoBox p {
	max-width: 300px;
	font-size: 14px;
	line-height: 1rem;
}

/* @media (min-width: 800px) and (max-width: 1200px) {
	.mapInfoBox {
		visibility: visible;
	}
} */

@media (max-width: 1200px) {
	.mapInfoBox {
		visibility: visible;
	}
}

@media (max-width: 500px) {
	.mapInfoBox svg {
		display: none;
	}
}
