svg {
	display: inline-block;
	vertical-align: middle;
}

.peer-group {
	color: #56b8bb;
	cursor: pointer;
}

.peer-group:hover {
	text-decoration: underline;
}

.loader {
	border-width: 16px;
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}

.moreInfoData {
	background-color: white;
	padding: 0.25rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.moreInfoData h2 {
	font-weight: bold;
	font-size: 18px;
}

.moreInfoData a {
	display: inline;
	color: #0984e3;
	text-decoration: underline;
}

.accordion-checkbox {
	display: none !important;
}

.accordion-header {
	display: flex;
	justify-content: space-between;
	align-items: center; /* Vertical centering */
	padding: 10px;
	cursor: pointer;
	background-color: #f1f1f1;
}

.arrow-icon {
	width: 0;
	height: 0;
	border-style: solid;
	display: inline-block;
	margin-left: 5px;
	vertical-align: middle; /* Adjusted for vertical centering */
}

.accordion-content {
	padding: 10px;
	display: none;
}

.accordion-content p {
	margin: 1rem 0;
}

.accordion-content.show {
	display: block;
}

.arrow-icon {
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 5px 0 5px;
	border-color: #000 transparent transparent transparent;
	transition: transform 0.3s;
}

.arrow-icon.open {
	transform: rotate(180deg);
}
