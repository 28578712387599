body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.social-progress-indicator {
  width: 200px;
  height: 20px;
  background-image: linear-gradient(
    to right,
    #fde68a,
    #d6d97b,
    #aecb72,
    #87bc6c,
    #5dac69,
    #38a077,
    #139381,
    #008585,
    #00778f,
    #006697,
    #005297,
    #1e3a8a
  );
}