.input {
    width: 100%;
    outline: none;
    border-width: 1px;
    border-color: rgba(0,0,0,0.10) !important;
    font-size: 0.90rem !important;
    padding: 5px !important;
    padding-left: 8px !important;
    border-radius: 5px !important;
}

.readonly {
    cursor: pointer;
}

.close {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    background-color: #FFFFFF;
    border-width: 0px;
    border-color: #767676;
    color: #767676;
    width: 20px;
    height: 20px;
    //border-radius: 50%;
    cursor: pointer;
    text-align: center;
    font-size: 0.80rem;
    overflow: hidden;
}

.close img {
    width: 100%;
    height: 100%;
}

.option {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #FFFFFF;
    color: #000000;
    cursor: pointer;
    font-size: 0.90rem;
    position: relative;
}

.option.category {
    //background-color: #eaeaea;
    background-color: #56B8BB;
    //color: #000000;
    color: #FFFFFF;
    font-weight: 400;
}

.option.category .toggleArrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.selected {
    //background-color: #56B8BB;
    background-color: rgba(86,184,187,0.70);
    color: #FFFFFF;
} 

.selected:hover {
    //background-color: #56B8BB;
    background-color: rgba(86,184,187,0.50);
    color: #FFFFFF;
}

.selectable:hover {
    background-color: rgba(86,184,187,0.50);
    //color: #FFFFFF;
    //color: #454545;
    color: #000000;
}