.partial-sc-top-container {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,0.10);
}
  
.partial-sc-top-part {
    float: left;
    width: 50%;
    padding-bottom: 5px;
}
  
.partial-sc-top-part.left {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgba(0,0,0,0.10);
    padding-top: 15px;
    text-align: left;
    min-height: 225px;
}
  
.partial-sc-top-part.right {
    padding: 20px;
    padding-top: 15px;
}
  
.partial-sc-overall-spi {
    padding-top: 20px;
    padding-bottom: 20px;
}
  
.partial-sc-overall-spi .section {
    display: inline-block;
    margin-left: 20px;
    margin-right: 10px;
    font-size: 0.75rem;
    line-height: 1;
    color: #000000;
    position: relative;
}

.partial-sc-overall-spi .section.section-indicator {
    width: 270px;
}

.scorecard-stat-box {
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.scorecard-stat-box .header {
    padding: 10px;
    padding-bottom: 5px;
    font-size: 0.90rem;
    text-align: left;
}

.scorecard-stat-box td {
    font-size: 0.85rem;
}

.scorecard-stat-box td.indicatorbar {
    width: 220px;
}

.scorecard-stat-box td.spi {
    width: 70px;
    text-align: center;
}

.scorecard-stat-box td.spi img {
    height: 10px;
    margin-right: 2px;
}

.scorecard-stat-box td.indicator {
    width: 30px;
    text-align: center;
}

.scorecard-stat-box td.compare-to {
    width: 60px;
    font-size: 0.70rem;
    line-height: 1.2;
    text-align: center;
}

.scorecard-stat-box td.compare-to img {
    width: 20px;
    display: inline !important;
}

.scorecard-stat-box td.toggle-arrow {
    width: 30px;
    font-size: 0.70rem;
    line-height: 1.2;
    text-align: center;
}

.scorecard-stat-box td.toggle-arrow img {
    width: 10px;
    display: inline !important;
}

.scorecard-stat-box td.toggle-arrow.toggle-arrow-drop:hover {
    cursor: pointer;
}
  
.all-stats-container {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
  
.partial-sc-all-stats-container .partial-sc-stat-table {
    padding: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: #56B8BB;
    border-radius: 15px;
    margin-bottom: 15px;
}
  
.partial-sc-all-stats-container .partial-sc-stat-table .table-title {
    font-size: 1.25rem;
    color: #56B8BB;
    font-weight: 600;
    text-align: left;
}
  
.partial-sc-stat-table .stat-table {
    margin-top: 15px;
}
  
.partial-sc-stat-table .stat-table .stat-table-row {
    font-weight: 600;
    font-size: 0.98rem;
}
  
.partial-sc-stat-table .stat-table .stat-table-row td {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0,0,0,0.10);
}
  
.stat-table-more-info {
    border-color: rgba(0,0,0,0.25);
    border-width: 1px;
    border-radius: 15px;
    font-size: 0.80rem;
    color: #56B8BB;
    font-weight: 600;
    text-align: center;
}

.stat-layout-small {
    display: none;
}
.stat-layout-big {
    display: block;
}

.tr-layout-small {
    display: none;
}

.tr-layout-big {
    display: table-row;
}

.td-layout-small {
    display: none;
}

.td-layout-big {
    display: table-cell;
}

.compare-to-more-info:hover {
    cursor: pointer;
}

@media(max-width: 760px){
    .stat-layout-small {
        display: block;
    }
    
    .stat-layout-big {
        display: none;
    }

    .tr-layout-small {
        display: table-row;
    }

    .tr-layout-big {
        display: none;
    }

    .td-layout-small {
        display: table-cell;
    }

    .td-layout-big {
        display: none;
    }

    td.stat-name-td {
        width: 80px;
    }

    td.stat-name-td div {
        width: 75px;
        white-space: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .scorecard-stat-box td.toggle-arrow {
        width: 60px;
    }

    .partial-sc-overall-spi {
        padding-left: 10px;
    }

    .partial-sc-overall-spi .section {
        margin-left: 10px;
    }

    .partial-sc-overall-spi .section.section-indicator {
        width: 155px;
    }

    .scorecard-stat-box td.spi {
        width: 60px;
        text-align: center;
        font-size: 0.75rem;
    }

    .scorecard-stat-box td.spi img {
        height: 8px;
    }

    .spi-top-value {
        //font-size: 0.80rem;
    }
}

@media(max-width: 400px){
    .partial-sc-overall-spi .section {
        margin-left: 5px;
        font-size: 0.60rem;
    }
    .partial-sc-overall-spi .section.section-indicator {
        width: 130px;
    }

    .scorecard-stat-box td.compare-to {
        width: 40px;
        font-size: 0.50rem;
    }
    
    .scorecard-stat-box td.compare-to img {
        width: 15px;
    }

    .scorecard-stat-box td.spi {
        width: 40px;
        font-size: 0.70rem;
    }
}

@media (min-width: 760px){
    .scorecard-legend .title {
        font-size: 1.0rem;
    }

    .scorecard-stat-box .header {
        font-size: 1.15rem;
    }

    .scorecard-stat-box td {
        font-size: 1.0rem;
    }
    
    .scorecard-stat-box td.spi {
        font-size: 0.95rem;
    }
}